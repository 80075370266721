<!-- eslint-disable prettier/prettier -->
<template>
    <div class="footer">
 <div class=" container-fluid position-relative overlay-top text-white-50 py-5" style="margin-top: 90px;">
        <div class="container mt-5 pt-5">
            <div class="row">
                <div class="rtl-dir col-md-12 mb-5">
                    <a href="/" class="navbar-brand" style="margin-right: 0rem; margin-left: 1rem;">
                        <img src="../assets/img/group_55.png" alt="logo">
                    </a>
                    <p id="footer_text" class="m-0 pr-5 pt-5 mr-5"></p>
                </div>
            </div>
            <div class="row">
                <div class="rtl-dir dir-rtl col-md-6 mb-5">
                    <h3 class="text-white mb-4">كن على تواصل معنا</h3>
                   <p><font-awesome-icon icon="fa-solid fa-phone"  /><span class="pr-1" id="phone_f"> </span></p>
                    <p><font-awesome-icon icon="fa-solid fa-envelope" /><span  class="pr-1" id="email_F"> </span></p>
                    <div class="d-flex justify-content-start mt-5">         
                        <a id="facebook-f" target="_blank"  class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands fa-facebook-f" size="2xl"/></a>
                        <a id="facebook-f-p" target="_blank"  class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands fa-facebook-f" size="2xl"/></a>            
                        <a id="telegram-f" target="_blank" class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands fa-telegram" size="2xl" /></a>
                        <a id="telegram-num-f" target="_blank" class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands fa-telegram" size="2xl" /></a>
                        <a id="youtube-f"  target="_blank"  class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands fa-youtube" size="2xl"/></a>
                        <a id="whats-app-num" target="_blank" class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands  fa-whatsapp" size="2xl" /></a>
                        <a id="whats-app-f" target="_blank" class="text-white ml-4" href=""><font-awesome-icon icon="fa-brands  fa-whatsapp" size="2xl" /></a>

                    </div>
                </div>
              
                <div class=" rtl-dir dir-rtl col-md-6 mb-5">
                    <h3 id="quick-links" class="text-white mb-4"></h3>
                    <div class="d-flex flex-column justify-content-center">
                        <a class="text-white-50 mb-2" href="/"><font-awesome-icon icon="fa-solid fa-angle-left" class=" ml-2"/>الرئيسية</a>
                        <a class="text-white-50 mb-2" href="/about" ><font-awesome-icon icon="fa-solid fa-angle-left" class=" ml-2"/>عن التطبيق  </a>
                        <a class="text-white-50 mb-2" href="/blogs"><font-awesome-icon icon="fa-solid fa-angle-left" class=" ml-2"/> اخر الأخبار  </a>
                        <a class="text-white-50 mb-2" href="/Contact"><font-awesome-icon icon="fa-solid fa-angle-left" class=" ml-2"/>تواصل معنا</a>
                        <a class="text-white-50 mb-2" href="/Terms&Condition"><font-awesome-icon icon="fa-solid fa-angle-left" class=" ml-2" /> الشروط والأحكام </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" container-fluid  text-white-50 border-top py-4" style="border-color: rgba(256, 256, 256, .1) !important;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center  mb-3 mb-md-0">
                    <p class="m-0">Copyright &copy; <a class="text-white" href="#">Tamer Gabr</a>. All Rights Reserved.
                    </p>
                </div>
              
            </div>
        </div>
    </div>
    </div>

</template>
<script>
    export default {
        name: 'footerPage',
        props: {},
        
    };
</script>