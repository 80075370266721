<!-- eslint-disable prettier/prettier -->
<template>
    <div class="pb-5">
    <vue-plyr >
            <div class="plyr__video-embed ">
                <iframe
                    id="video_link"
                    src="https://www.youtube.com/embed/wzgW5kQS-Ao?]&amp;iv_load_policy=3&amp;modestbranding=1&amp;mute=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
                    allowtransparency
                    allow=" accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                >
                
                </iframe>
            </div>
        </vue-plyr>
    
        
    </div>
</template>

<script>
   
    export default {
        name: 'vidCom',
        data() {
            return {};
        },
        components: {},
        mounted() {
            
          
   
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
