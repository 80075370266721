<!-- eslint-disable prettier/prettier -->
<template>
 <div class="jumbotron jumbotron-fluid position-relative overlay-bottom" style="margin-bottom: 90px;">
        <div class="container  my-5 ">
            <div class=" container ">
            <div class="row ">
             <div class="  col-lg-8">
            <h1 class="text-white mt-4 mb-4" > الأستاذ</h1>
            <h1 class="text-white  display-1 aniname mr-5 mb-5">تامر جبر</h1>
            <div class="mx-auto mb-5" >
                <h4 id="header_text_1" class="  text-center text-white ">  </h4>
                <h4 id="header_text_2" class="  text-center text-white "> </h4>
                <h4 id="header_text_3" class="  text-center text-white "> </h4>
                

            </div></div>
        
        <div class="col-lg-4  mb-lg-0" style="min-height: 500px;">
                    <div class=" aniImg h-100">
                        <img class=" w-100 h-100" src="../assets/img/118358995_3227388414007472_3842607331419479228_n@3x.png" style="object-fit: cover;">
                    </div>
                </div>
    </div></div></div></div>
</template>
<script>

    export default {
        name: 'headerHome',
        props: {},
        components: {
           
  },
    };
</script>
<style scoped lang="scss">


</style>