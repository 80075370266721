<!-- eslint-disable prettier/prettier -->
<template>
    <div class="navBar container-fluid p-0">
        <nav
            class="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 px-lg-5"
        >
            <a href="/" class="navbar-brand ml-lg-3">
                <img src="../assets/img/group_55.png" alt="logo">
            </a>
            <button
                @click="onclickk"
                type="button"
                class="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                class="collapse navbar-collapse justify-content-between px-lg-3"
                id="navbarCollapse"
            >
                <div class="navbar-nav mx-auto py-0">
                    <a id="homeLink" class="active nav-item nav-link" href="/">
                        الرئيسية
                    </a>
                    <a id="aboutLink" class="nav-item nav-link" href="/about"
                        >عن التطبيق   
                    </a>
                    <a id="blogsLink" class="nav-item nav-link" href="/blogs"
                        > اخر الأخبار 
                    </a>
                    <a  id="contactLink" class="nav-item nav-link" href="/Contact"
                        >تواصل معنا
                    </a>
                </div>
                <a target="_blank" rel="noopener noreferrer" style="padding: 0;" class="nav-link app"
                  href="https://play.google.com/store/apps/details?id=net.viedev.gabr">
                  <img src="../assets/img/playstore.png">
                </a>
            </div>
        </nav>
    </div>
</template>
<script>

    export default {
        name: 'navbarHome',
        props: {},
        components: {



      },
      methods: {
        onclickk(){
    console.log("ss");
    const nav = document.querySelector("#navbarCollapse");
   
      if (nav.style.display === 'block') {
        nav.style.display = 'none';
        console.log("nin");
      } else{
        nav.style.display = 'block';
        console.log(",");
      }
    
    
    
    }}
      };
</script>
