<!-- eslint-disable prettier/prettier -->
<template>
    <div class="container-fluid px-0">
        <div class="row justify-content-center bg-image mx-0">
            <div class="col-lg-6 py-5">
                <div class="bg-white p-5 my-5 cta">
                    <h4 class="text-center mb-4">تطبيق جبر للفيزياء</h4>
                    <h2 id="Download_header" class="text-center mb-4"></h2>
                    <h4 id="download_text" class="text-center mb-4"></h4>
                   
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=net.viedev.gabr"
                    >
                        <img src="../assets/img/playstore.png" />
                    </a>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-content-center pt-5">
            <div class="col-lg-6">
                <div class="section-title text-center position-relative mb-4">
                    <h6
                        id="blogs_title"
                        class="d-inline-block position-relative text-secondary text-uppercase pb-2"
                    ></h6>
                    <h1 id="blogs_heading" class="display-4"></h1>
                </div>
            </div>
        </div>
        <div
           
       
            class="owl-carousel courses-carousel"
        >
         <div  id="news" class="owl-item"></div>
          
        </div>

   


    </div>
</template>
<script>

    export default {
        name: 'ourBlog',
        data() {
            return {
                items: 4,
                windowWeight: window.innerWidth,
            };
        },
        components: {
         
        },
        mounted() {
            this.windowWeight = window.innerWidth;
            if (this.windowWeight <= 500) {
                this.items = 1;
            } else if (this.windowWeight <= 570) {
                this.items = 2;
            } else if (this.windowWeight <= 770) {
                this.items = 3;
            } else {
                console.log('b');
                this.items = 4;
            }
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            });
        },

        watch: {
            // whenever question changes, this function will run
            windowWeight: function (n, w) {
                console.log(n, w);

                if (n <= 500) {
                    this.items = 1;
                } else if (n <= 570) {
                    this.items = 2;
                } else if (n <= 770) {
                    this.items = 3;
                } else {
                    this.items = 4;
                }
            },
        },

        methods: {
            onResize() {
                this.windowWeight = window.innerWidth;
            },
        },
    };
</script>
